<template>
  <div class="BackgroundStarsBorders">
  <svg viewBox="0 0 297 210" id="original" class="svgAnimator">

    <g id="layer1" transform="translate(0,-87)">
      <path style="opacity:0.1;fill:#d522ab;fill-opacity:1;stroke:none;stroke-width:0.90017289;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1" d="m 414.6606,472.94696 -631.1225,-7.75873 23.09716,-471.3770861 c 3.69074,15.5117867 36.99147,39.5250931 86.66072,65.8260301 28.901151,15.303782 59.204556,20.997022 74.096339,57.774436 14.627246,36.12411 65.718315,49.41199 71.636574,63.4225 16.158594,38.25284 28.248689,48.33069 83.681947,76.11698 20.06776,10.05909 32.19999,45.7016 88.81167,55.26626 54.69885,9.24148 57.64671,72.09645 100.48011,99.2729 56.62714,35.92814 95.98644,56.73346 102.65798,61.45671 z" id="path8450"></path>
      <path style="opacity:0.1;fill:#d522ab;fill-opacity:1;stroke:none;stroke-width:0.79374999;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1" inkscape:transform-center-x="-23.920995" inkscape:transform-center-y="-1.0361596e-06" d="m 342.83946,464.21 -559.30136,0.97823 20.94684,-432.462915 c 3.00219,12.617909 39.70815,26.34546 64.20019,61.9167 18.94852,27.520055 50.947298,50.940785 80.702145,71.530715 32.738345,22.65446 57.8789908,56.14245 71.145002,59.47401 56.63119,14.2221 38.491349,71.95802 76.648582,85.48804 29.643891,10.51131 19.017201,21.13679 69.608741,33.51325 39.56859,9.67986 60.10291,52.09166 92.00113,62.01143 57.26829,17.80939 78.09115,53.33275 84.04873,57.55054 z" id="path8450-0"></path>
      <path style="opacity:0.1;fill:#d522ab;fill-opacity:1;stroke:none;stroke-width:0.79374999;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1" inkscape:transform-center-x="-23.920995" inkscape:transform-center-y="-1.0361596e-06" d="m 305.35115,463.55767 -521.81305,1.63056 20.68585,-394.838387 c 3.88683,16.335912 30.66618,23.329251 62.08443,67.694957 17.47674,24.6789 51.319219,34.45344 65.725482,58.15279 17.867231,29.39289 54.556766,32.65477 58.045424,43.95117 16.5110545,53.46336 55.009665,26.43933 71.891454,71.95687 15.238752,41.0875 44.36008,12.45094 69.25626,50.69506 21.1602,32.50511 19.92785,28.20434 69.96524,40.83668 44.71948,11.28979 98.42773,55.86279 104.15891,59.9203 z" id="path8450-0-7" inkscape:connector-curvature="0" sodipodi:nodetypes="cccssssssc"></path>
      <path style="opacity:0.1;fill:#22abd5;fill-opacity:1;stroke:none;stroke-width:0.90017289;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1" inkscape:transform-center-x="27.128231" inkscape:transform-center-y="-3.4509061e-06" d="m -37.978017,-68.609954 540.086987,-5.225984 5.95025,487.385598 C 503.43227,394.10309 441.82934,314.74664 396.30639,299.55926 357.72784,286.68867 374.3428,265.64511 335.33636,240.21904 305.4098,220.71161 254.93858,199.13074 234.84416,163.78265 210.01324,120.10257 198.69887,122.60082 170.06102,116.39187 120.98559,105.75186 151.88251,74.829909 104.41544,59.688909 65.592092,47.305075 47.719474,18.281068 27.552712,-18.978525 7.6761211,-55.702005 -32.164634,-64.494248 -37.978017,-68.609954 Z" id="path8450-5" inkscape:connector-curvature="0" sodipodi:nodetypes="cccssssssc"></path>
      <path style="opacity:0.1;fill:#22a5d5;fill-opacity:1;stroke:none;stroke-width:0.79374999;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1" inkscape:transform-center-x="23.920995" inkscape:transform-center-y="-1.0361597e-06" d="M -1.6866428,-71.21228 502.10897,-73.835938 504.91788,393.5343 C 500.81997,376.31122 469.09883,323.92898 428.6488,297.1221 400.44179,278.42887 417.64495,251.14379 375.62965,230.66658 338.13332,212.3918 311.51743,187.58126 282.82292,171.88668 222.73426,139.02095 236.81271,143.80587 224.3172,105.29745 222.03834,98.274497 203.83283,76.200283 158.07676,59.875982 131.82409,50.509867 101.0179,16.049492 81.45861,-8.6934752 49.608345,-48.984812 4.625092,-66.743755 -1.6866428,-71.21228 Z" id="path8450-0-5" inkscape:connector-curvature="0" sodipodi:nodetypes="cccssssssc"></path>
      <path style="opacity:0.1;fill:#22a5d5;fill-opacity:1;stroke:none;stroke-width:0.79374999;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1" inkscape:transform-center-x="23.920995" inkscape:transform-center-y="-1.0361596e-06" d="m 41.838949,-71.427173 460.270021,-2.408765 0.75595,415.949748 c -3.77318,-15.85827 -28.73943,-33.57103 -53.31311,-86.4472 -16.40592,-35.30127 -8.53108,-42.92804 -54.02864,-51.83883 -32.69573,-6.40353 -32.50596,-16.63096 -54.36874,-38.40277 C 312.5614,136.95094 279.17828,134.89543 258.77137,92.231369 256.4568,87.392381 225.18249,52.787686 192.5154,18.722396 171.09571,-3.6140814 121.08476,-7.4086979 99.869241,-33.753672 74.851272,-64.820456 47.541991,-67.389583 41.838949,-71.427173 Z" id="path8450-0-7-3" inkscape:connector-curvature="0" sodipodi:nodetypes="cccssssssc"></path>
    </g>
  </svg>
  </div>
</template>

<script>

export default {
  name: 'BackgroundStarsBorders'
}
</script>

<style language="scss" scoped>

  .BackgroundStarsBorders, #original{
    width: auto;
    height: 100%;
    z-index: 5;
  }

  @media only screen and (min-width: 600px) {
    .BackgroundStarsBorders, #original {
      width: 100%;
      width: -moz-available;
      width: -webkit-fill-available;
      width: fill-available;
      height: 100%;
      height: -moz-available;
      height: -webkit-fill-available;
      height: fill-available;
    }
  }

  #original.svgAnimator #path8450 {
    animation: svgA_0 6s 0s infinite alternate;
  }

  #original.svgAnimator #path8450-0 {
    animation: svgA_1 6s 0s infinite alternate;
  }

  #original.svgAnimator #path8450-0-7 {
    animation: svgA_2 6s 0s infinite alternate;
  }

  #original.svgAnimator #path8450-5 {
    animation: svgA_3 6s 0s infinite alternate;
  }

  #original.svgAnimator #path8450-0-5 {
    animation: svgA_4 6s 0s infinite alternate;
  }

  #original.svgAnimator #path8450-0-7-3 {
    animation: svgA_5 6s 0s infinite alternate;
  }


  @keyframes svgA_0 {
    0.0000% {
      d:path('m 414.6606,472.94696 -631.1225,-7.75873 23.09716,-471.3770861 c 3.69074,15.5117867 36.99147,39.5250931 86.66072,65.8260301 28.901151,15.303782 59.204556,20.997022 74.096339,57.774436 14.627246,36.12411 65.718315,49.41199 71.636574,63.4225 16.158594,38.25284 28.248689,48.33069 83.681947,76.11698 20.06776,10.05909 32.19999,45.7016 88.81167,55.26626 54.69885,9.24148 57.64671,72.09645 100.48011,99.2729 56.62714,35.92814 95.98644,56.73346 102.65798,61.45671 z');
    }
    100.0000% {
      d:path('m 414.6606,472.94696 -631.1225,-7.75873 23.09716,-471.3770861 c 3.69074,15.5117867 60.17104,16.2571861 86.66072,65.8260301 13.026151,24.375211 37.60013,63.012356 74.852291,76.673246 42.80618,15.69763 65.437316,66.22374 78.440147,71.73798 46.257,19.61667 49.236591,28.52851 59.491482,67.80149 1.74156,6.66961 45.80712,47.21351 102.4188,56.77817 54.69885,9.24148 80.32528,42.61431 103.50392,78.86219 36.12851,56.49951 95.98644,56.73346 102.65798,61.45671 z');
    }
  }

  @keyframes svgA_1 {
    0.0000% {
      d:path('m 342.83946,464.21 -559.30136,0.97823 20.94684,-432.462915 c 3.00219,12.617909 39.70815,26.34546 64.20019,61.9167 18.94852,27.520055 50.947298,50.940785 80.702145,71.530715 32.738345,22.65446 57.8789908,56.14245 71.145002,59.47401 56.63119,14.2221 38.491349,71.95802 76.648582,85.48804 29.643891,10.51131 19.017201,21.13679 69.608741,33.51325 39.56859,9.67986 60.10291,52.09166 92.00113,62.01143 57.26829,17.80939 78.09115,53.33275 84.04873,57.55054 z');
    }
    100.0000% {
      d:path('m 342.83946,464.21 -559.30136,0.97823 20.94684,-432.462915 c 3.00219,12.617909 21.8162,53.6239 64.20019,61.9167 36.335426,7.109345 46.088683,65.568505 79.946193,78.334285 38.030011,14.33899 79.929719,44.40293 81.728335,57.96211 9.006189,67.89472 39.962602,21.27724 52.458106,59.78566 2.429602,7.4875 33.380296,41.5475 83.971836,53.92396 39.56859,9.67986 60.10291,52.09166 92.00113,62.01143 57.26829,17.80939 78.09115,53.33275 84.04873,57.55054 z');
    }
  }

  @keyframes svgA_2 {
    0.0000% {
      d:path('m 305.35115,463.55767 -521.81305,1.63056 20.68585,-394.838387 c 3.88683,16.335912 30.66618,23.329251 62.08443,67.694957 17.47674,24.6789 51.319219,34.45344 65.725482,58.15279 17.867231,29.39289 54.556766,32.65477 58.045424,43.95117 16.5110545,53.46336 55.009665,26.43933 71.891454,71.95687 15.238752,41.0875 44.36008,12.45094 69.25626,50.69506 21.1602,32.50511 19.92785,28.20434 69.96524,40.83668 44.71948,11.28979 98.42773,55.86279 104.15891,59.9203 z');
    }
    100.0000% {
      d:path('m 305.35115,463.55767 -521.81305,1.63056 20.68585,-394.838387 c 3.88683,16.335912 25.80705,63.472687 75.69157,85.081857 27.30412,11.82771 24.388455,59.16186 52.118342,59.6647 36.766041,0.6667 65.4621522,41.88913 76.9442338,44.70712 39.1896262,9.61812 33.3416762,14.17376 52.9926442,52.30211 2.57754,5.00115 17.860682,33.44091 62.45269,43.13554 34.76734,7.55868 48.06639,54.90746 99.44738,59.73549 47.74329,4.48622 75.74916,44.5235 81.48034,48.58101 z');
    }
  }

  @keyframes svgA_3 {
    0.0000% {
      d:path('m -37.978017,-68.609954 540.086987,-5.225984 5.95025,487.385598 C 503.43227,394.10309 441.82934,314.74664 396.30639,299.55926 357.72784,286.68867 374.3428,265.64511 335.33636,240.21904 305.4098,220.71161 254.93858,199.13074 234.84416,163.78265 210.01324,120.10257 198.69887,122.60082 170.06102,116.39187 120.98559,105.75186 151.88251,74.829909 104.41544,59.688909 65.592092,47.305075 47.719474,18.281068 27.552712,-18.978525 7.6761211,-55.702005 -32.164634,-64.494248 -37.978017,-68.609954 Z');
    }
    100.0000% {
      d:path('m -37.978017,-68.609954 540.086987,-5.225984 5.95025,487.385598 C 503.43227,394.10309 418.39482,301.1395 372.87187,285.95212 334.29332,273.08153 337.30113,257.32963 308.87803,225.85594 284.93554,199.34375 257.20644,188.54741 246.9394,184.19336 200.6824,164.57669 197.76647,158.99026 183.66816,120.92758 171.21327,87.301831 155.66227,69.538242 108.1952,54.397242 69.371854,42.013408 47.719474,18.281068 27.552712,-18.978525 7.6761211,-55.702005 -32.164634,-64.494248 -37.978017,-68.609954 Z');
    }
  }

  @keyframes svgA_4 {
    0.0000% {
      d:path('M -1.6866428,-71.21228 502.10897,-73.835938 504.91788,393.5343 C 500.81997,376.31122 469.09883,323.92898 428.6488,297.1221 400.44179,278.42887 417.64495,251.14379 375.62965,230.66658 338.13332,212.3918 311.51743,187.58126 282.82292,171.88668 222.73426,139.02095 236.81271,143.80587 224.3172,105.29745 222.03834,98.274497 203.83283,76.200283 158.07676,59.875982 131.82409,50.509867 101.0179,16.049492 81.45861,-8.6934752 49.608345,-48.984812 4.625092,-66.743755 -1.6866428,-71.21228 Z');
    }
    100.0000% {
      d:path('M -1.6866428,-71.21228 502.10897,-73.835938 504.91788,393.5343 C 500.81997,376.31122 418.45002,305.78612 401.43451,274.44353 385.28954,244.70446 367.75209,236.78069 325.73679,216.30348 288.24046,198.0287 264.64838,172.46221 263.16816,161.30335 254.16197,93.408631 223.20557,140.02611 210.71006,101.51769 208.4312,94.494735 184.93402,59.56933 147.49343,47.024791 121.06405,38.169576 101.0179,16.049492 81.45861,-8.6934752 49.608345,-48.984812 4.625092,-66.743755 -1.6866428,-71.21228 Z');
    }
  }

  @keyframes svgA_5 {
    0.0000% {
      d:path('m 41.838949,-71.427173 460.270021,-2.408765 0.75595,415.949748 c -3.77318,-15.85827 -28.73943,-33.57103 -53.31311,-86.4472 -16.40592,-35.30127 -8.53108,-42.92804 -54.02864,-51.83883 -32.69573,-6.40353 -32.50596,-16.63096 -54.36874,-38.40277 C 312.5614,136.95094 279.17828,134.89543 258.77137,92.231369 256.4568,87.392381 225.18249,52.787686 192.5154,18.722396 171.09571,-3.6140814 121.08476,-7.4086979 99.869241,-33.753672 74.851272,-64.820456 47.541991,-67.389583 41.838949,-71.427173 Z');
    }
    100.0000% {
      d:path('m 41.838949,-71.427173 460.270021,-2.408765 0.75595,415.949748 c -3.77318,-15.85827 -55.19776,-44.91032 -79.77144,-97.78649 -16.40592,-35.30127 -72.78704,-55.02327 -83.51079,-77.54121 -14.32506,-30.08003 -46.68051,-36.71525 -56.63659,-39.15872 -39.18962,-9.61812 -31.73806,-9.36291 -51.38902,-47.491259 -2.4574,-4.76804 -7.88649,-34.152017 -45.0893,-40.247068 C 155.92786,34.885613 127.13238,3.1746356 105.91686,-23.170339 80.898891,-54.237123 47.541991,-67.389583 41.838949,-71.427173 Z');
    }
  }
</style>
