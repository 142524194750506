<template>
<svg
   width="250"
   height="300"
   viewBox="0 0 66.145832 79.374999"
   version="1.1"
   id="alien"
>
  <defs
     id="defs2">
    <filter
       style="color-interpolation-filters:sRGB"
       id="filter5164"
       x="-0.027137073"
       width="1.0542741"
       y="-0.044103667"
       height="1.0882073">
      <feGaussianBlur
         inkscape:collect="always"
         stdDeviation="0.20824041"
         id="feGaussianBlur5166" />
    </filter>
  </defs>
  <g
     inkscape:label="Layer 1"
     inkscape:groupmode="layer"
     id="layer1"
     transform="translate(0,-217.625)">
    <path
       style="fill:#8b1424;fill-opacity:1;stroke:#8b1424;stroke-width:0.52899998;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
       d="m 18.771103,290.90942 -0.168058,0.64117 c 0.458834,0.98719 0.671468,1.76314 1.130303,2.75034 1.128899,0.98377 2.81794,0.15462 2.960588,-0.44202 -0.633284,-1.34412 -3.019369,-2.40619 -3.922833,-2.94949 z"
       id="path4915"
       inkscape:connector-curvature="0"
       sodipodi:nodetypes="ccccc" />
    <path
       style="fill:#f4d3b2;fill-opacity:1;stroke:#d47b1d;stroke-width:0.5291667;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
       d="m 18.744369,253.02378 c -0.177229,0.26581 3.994904,2.22897 3.886967,2.44501 -0.950117,1.90167 -0.696069,1.94101 2.512267,2.75468 0.861208,0.30095 1.29388,0.41759 1.661645,2.11513 0.02447,0.11297 0.411633,5.57268 0.71764,7.46125 0.07115,0.43915 2.553396,4.16668 3.839533,5.65986 0.768965,0.72822 -1.502959,-0.2382 -2.126344,1.13875 -0.332635,-0.24825 -0.565574,-0.45559 -0.935749,-0.82636 -1.457004,-1.45936 -3.060049,-3.62271 -3.669677,-4.55925 -0.217697,-0.78466 -0.220913,-1.21727 -0.478455,-4.36731 -0.0032,-0.0391 -2.092665,6.39277 -4.116891,8.60722 -0.639377,0.69946 -5.788994,-7.49698 -6.992712,-10.3711 -0.240283,-0.57373 0.08464,-1.97153 0.406861,-3.4772 0.0634,-0.29623 2.177287,-2.17731 3.196266,-2.37026 3.609602,-0.6835 3.022621,-2.48826 2.44475,-2.20001"
       id="path4795"
       inkscape:connector-curvature="0"
       sodipodi:nodetypes="cscssccscsscssc" />
    <path
       style="fill:#f4d3b2;fill-opacity:1;stroke:#d47b1d;stroke-width:0.5291667;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
       d="m 24.550566,263.32947 c -0.100667,0.70093 -0.273627,1.4016 -0.535791,1.96099 l -0.760529,2.42797 c -0.126995,2.99516 0.333597,3.4781 0.69128,4.51999 l 4.916905,4.63019 c 0.956761,0.76112 1.533148,1.96526 1.304759,2.86354 l -1.201835,4.75009 c -0.300324,1.30143 -3.734404,4.61752 -3.734404,3.37171 -0.199722,-2.58281 -0.430933,-4.29517 -0.156488,-7.35215 l -6.312171,-5.16892"
       id="path4826"
       inkscape:connector-curvature="0"
       sodipodi:nodetypes="cccccccccc" />
    <path
       style="fill:#8b1424;fill-opacity:1;stroke:#8b1424;stroke-width:0.49908695;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
       d="m 24.756332,290.81747 -0.05209,0.75185 c 0.43575,0.92467 0.720368,1.46249 1.156119,2.38716 1.423701,0.86287 2.596164,0.33337 2.728957,-0.22503 0.376717,-1.05686 -2.920282,-2.35264 -3.832986,-2.91398 z"
       id="path4915-3"
       inkscape:connector-curvature="0"
       sodipodi:nodetypes="ccccc" />
    <path
       style="fill:#e85b6e;fill-opacity:1;stroke:#ce1d35;stroke-width:0.5291667;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
       d="m 29.195501,283.51166 -0.230146,0.97058 -1.787134,5.48169 c 0.359299,0.38132 0.812992,1.09301 1.320338,1.99251 0.49155,0.80394 0.349546,1.48938 -0.04214,1.99891 -0.581782,0.38276 -1.388684,0.26283 -2.089428,-0.38931 l -1.464149,-2.28408 c 0,0 -0.572879,-0.79843 0.32811,-1.51896 -0.110608,-1.43041 -0.165603,-3.98256 -0.268871,-5.47054 -0.02229,-0.32109 -0.0404,-0.44939 -0.05265,-0.77932 1.498123,0.79981 3.556089,0.73121 4.286062,-0.001 z"
       id="path4826-8"
       inkscape:connector-curvature="0"
       sodipodi:nodetypes="ccccccccsccc" />
    <path
       style="fill:#f4d3b2;fill-opacity:1;stroke:#d47b1d;stroke-width:0.529;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
       d="m 12.743692,261.67141 c 0.305158,2.58023 1.837732,6.15312 0.803556,9.98526 -0.501082,1.14777 -0.441536,3.07304 -0.111236,4.01296 l 7.00015,5.19113 c -1.083266,2.28194 -0.939077,6.53538 -1.094823,8.86885 -0.42266,0.23066 -0.629294,0.54374 -0.570236,1.17981 l 1.35677,2.85119 c 0.669776,0.74788 1.721121,0.72934 2.359602,0.3146 0.387207,-0.41294 0.38418,-1.08708 -0.03933,-1.76969 -0.129385,-0.20856 -0.716159,-1.91847 -1.199465,-2.28095 l 4.493166,-10.04162 c 0.126715,-0.64809 0.247896,-1.29741 -0.547159,-2.15034 l -4.91912,-4.54063"
       id="path4824"
       inkscape:connector-curvature="0"
       sodipodi:nodetypes="ccccccccscccc" />
    <path
       style="opacity:0.906;fill:#e5f9fc;fill-opacity:1;stroke:#6edcef;stroke-width:0.31145141;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;filter:url(#filter5164)"
       d="m 41.693618,255.70591 12.024874,0.6229 c 1.218433,0.0631 2.601198,1.04929 2.202299,2.2023 l -2.180162,6.30174 c -0.398897,1.15302 -0.982228,2.2023 -2.202297,2.2023 l -11.713424,1e-5 c -1.220069,0 -2.520172,-1.02436 -2.202291,-2.20231 l 1.868708,-6.92465 c 0.31788,-1.17793 0.983858,-2.26541 2.202293,-2.20229 z"
       id="rect4797"
       inkscape:connector-curvature="0"
       sodipodi:nodetypes="sssssssss" />
    <path
       style="fill:#f4d3b2;fill-opacity:1;stroke:#d47b1d;stroke-width:0.5291667;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
       d="m 18.678617,256.5335 c -0.120485,0.0156 -3.009883,0.62353 -5.042068,0.76982 -0.706732,0.0508 -1.295906,0.3903 -1.32386,1.55855 -0.917973,2.58148 -3.406819,5.98331 -4.150949,8.69647 0.01837,2.60358 0.228848,3.00534 0.44673,5.70613 0.764831,-0.89634 2.054991,-1.09253 3.024572,0.17716 -0.719817,-4.04566 -0.412622,-4.73077 -0.431641,-5.99353 0.66333,-0.99192 1.541134,-2.12312 3.050401,-4.16746"
       id="path4747-2"
       inkscape:connector-curvature="0"
       sodipodi:nodetypes="cscccccc" />
    <path
       style="fill:#e85b6e;fill-opacity:1;stroke:#ce1d35;stroke-width:0.5291667;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
       d="m 19.66961,283.93109 c -0.360672,0.0515 -0.364761,4.32958 -0.328281,5.79852 -0.422663,0.23066 -0.629297,0.54374 -0.570236,1.17981 l 1.356766,2.85119 c 0.66978,0.74788 1.721125,0.72934 2.359605,0.3146 0.387207,-0.41294 0.384183,-1.08708 -0.03932,-1.76969 -0.129385,-0.20856 -0.716159,-1.91847 -1.199464,-2.28095 l 2.349599,-5.25104 c -2.584991,0.0905 -3.162864,-0.3905 -3.928672,-0.84244 z"
       id="path4824-5"
       inkscape:connector-curvature="0"
       sodipodi:nodetypes="cccccsccc" />
    <g
       id="g895"
       transform="rotate(3.6277269,16.492135,273.30396)">
      <path
         sodipodi:nodetypes="ccsscccccc"
         inkscape:connector-curvature="0"
         id="path4747"
         d="m 20.390048,254.44744 c 2.139574,2.35739 3.493228,2.90482 5.838076,4.42674 2.207606,0.95667 3.049642,1.1457 5.463896,-1.63031 2.94572,-3.3871 5.169118,-6.8164 5.830163,-10.90503 0.594591,-3.67758 0.596793,-5.58385 -0.167324,-11.23809 -0.749772,-5.22469 -2.684648,-14.21305 -11.680383,-15.35935 -7.603302,-0.34094 -8.62863,0.71941 -11.916347,3.2695 -6.8688154,7.03527 -3.65748,19.20221 -1.586561,22.92002 1.954139,4.07688 3.729495,5.86576 5.325952,8.34573 0.0336,1.20219 -0.01351,1.92928 -0.333301,2.34406"
         style="fill:#eaeaea;fill-opacity:1;stroke:#818181;stroke-width:0.26458332;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
      <path
         sodipodi:nodetypes="scsss"
         inkscape:connector-curvature="0"
         id="path4749"
         d="m 29.213148,244.51042 c 0,3.24408 -1.586953,5.98529 -3.763453,5.44134 -2.112552,-0.52797 -3.684798,-2.19726 -3.684798,-5.44134 0,-3.24409 2.650513,-5.40201 4.707291,-5.40201 2.056776,0 2.74096,2.15792 2.74096,5.40201 z"
         style="opacity:1;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.5291667;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1" />
      <path
         sodipodi:nodetypes="scscs"
         inkscape:connector-curvature="0"
         id="path4749-6"
         d="m 36.790085,244.29076 c 0,2.98257 -1.640925,5.16793 -3.501212,5.28418 -1.860285,0.19375 -2.950639,-2.30161 -2.950639,-5.28418 0,-2.9826 0.500454,-5.24545 2.282084,-5.24545 1.781629,0 4.169767,2.26285 4.169767,5.24545 z"
         style="opacity:1;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.5291667;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1" />
      <path
         sodipodi:nodetypes="cc"
         inkscape:connector-curvature="0"
         id="path4767"
         d="m 27.650138,255.034 c 1.494412,0.63091 3.303438,0.0811 3.303438,-0.0786"
         style="fill:none;stroke:#727272;stroke-width:0.5291667;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
      <path
         inkscape:connector-curvature="0"
         id="path4771"
         d="m 30.347891,251.14536 a 0.17737933,0.29198567 67.168597 0 1 -0.200283,0.27679 0.17737933,0.29198567 67.168597 0 1 -0.337934,-0.0502 0.17737933,0.29198567 67.168597 0 1 0.200282,-0.27678 0.17737933,0.29198567 67.168597 0 1 0.337935,0.0503 z"
         style="opacity:1;fill:#828282;fill-opacity:1;stroke:none;stroke-width:0.5291667;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1" />
      <path
         inkscape:connector-curvature="0"
         id="path4771-6"
         d="m 30.951976,251.11848 a 0.1510304,0.24861247 67.168596 0 1 -0.170531,0.23567 0.1510304,0.24861247 67.168596 0 1 -0.287736,-0.0427 0.1510304,0.24861247 67.168596 0 1 0.170529,-0.23566 0.1510304,0.24861247 67.168596 0 1 0.287738,0.0427 z"
         style="opacity:1;fill:#828282;fill-opacity:1;stroke:none;stroke-width:0.5291667;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1" />
      <path
         inkscape:connector-curvature="0"
         id="path4788"
         d="m 17.535203,250.06119 a 0.747467,0.58136321 75.057118 0 1 -0.368964,0.87209 0.747467,0.58136321 75.057118 0 1 -0.754443,-0.57228 0.747467,0.58136321 75.057118 0 1 0.368964,-0.8721 0.747467,0.58136321 75.057118 0 1 0.754443,0.57229 z"
         style="opacity:1;fill:#9e9e9e;fill-opacity:1;stroke:none;stroke-width:0.5291667;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1" />
      <path
         sodipodi:nodetypes="cc"
         inkscape:connector-curvature="0"
         id="path4793"
         d="m 30.009741,248.14236 c 0.03603,0.75686 0.237004,1.1453 0.647286,2.39606"
         style="fill:none;stroke:#9d9d9d;stroke-width:0.5291667;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
      <path
         sodipodi:nodetypes="cc"
         inkscape:connector-curvature="0"
         id="path4838"
         d="m 22.347319,239.78303 c 1.505715,-1.84612 3.408407,-2.55127 5.061084,-2.44712"
         style="fill:none;stroke:#9d9d9d;stroke-width:0.5291667;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
      <path
         sodipodi:nodetypes="cc"
         inkscape:connector-curvature="0"
         id="path4840"
         d="m 31.357159,237.00222 c 2.329523,0.0406 3.384304,1.34421 4.39369,2.55834"
         style="fill:none;stroke:#9d9d9d;stroke-width:0.5291667;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
      <path
         sodipodi:nodetypes="cc"
         inkscape:connector-curvature="0"
         id="path4842"
         d="m 24.015809,250.68383 c 0.721671,0.53517 1.831569,0.84915 3.114515,0.30588"
         style="fill:none;stroke:#9d9d9d;stroke-width:0.5291667;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
      <path
         sodipodi:nodetypes="cc"
         inkscape:connector-curvature="0"
         id="path4846"
         d="m 32.636338,250.62822 c 0.922395,0.004 1.811482,-0.0549 2.36369,-0.6396"
         style="fill:none;stroke:#9d9d9d;stroke-width:0.5291667;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
      <path
         sodipodi:nodetypes="ccccc"
         inkscape:connector-curvature="0"
         id="path4848"
         d="m 26.529327,240.28686 c 0.036,-0.26223 0.1581,-0.46785 0.481752,-0.44244 0.857528,0.47777 1.20565,1.32013 1.346941,2.63489 0.01848,0.33092 -0.266435,0.44213 -0.619397,0.28512 -0.233416,-1.26417 -0.499826,-1.69315 -1.209296,-2.47757 z"
         style="fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.5291667;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
      <path
         inkscape:connector-curvature="0"
         id="path4850"
         d="m 28.298263,243.70399 a 0.27808158,0.36150607 0 0 1 -0.27808,0.3615 0.27808158,0.36150607 0 0 1 -0.278081,-0.3615 0.27808158,0.36150607 0 0 1 0.278081,-0.36151 0.27808158,0.36150607 0 0 1 0.27808,0.36151 z"
         style="opacity:0.97699998;fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.5291667;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1" />
      <path
         sodipodi:nodetypes="ccccc"
         inkscape:connector-curvature="0"
         id="path4848-6"
         d="m 33.612522,240.5075 c 0.03603,-0.26223 0.158096,-0.46785 0.481751,-0.44243 0.79033,0.47777 1.502099,1.2367 1.791869,2.55146 0.01848,0.33092 -0.322047,0.35869 -0.675013,0.2017 -0.348662,-1.29774 -1.043609,-1.72656 -1.598607,-2.31073 z"
         style="fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.5291667;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
      <path
         inkscape:connector-curvature="0"
         id="path4850-5"
         d="m 36.048853,243.60483 a 0.27808158,0.36150607 0 0 1 -0.278082,0.3615 0.27808158,0.36150607 0 0 1 -0.278081,-0.3615 0.27808158,0.36150607 0 0 1 0.278081,-0.36151 0.27808158,0.36150607 0 0 1 0.278082,0.36151 z"
         style="opacity:0.97699998;fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.5291667;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1" />
      <path
         sodipodi:nodetypes="ccccc"
         inkscape:connector-curvature="0"
         id="path4871"
         d="m 22.847865,246.67945 c 0.02282,-0.22021 0.100248,-0.37803 0.305891,-0.38931 0.480533,1.13313 0.927285,1.2385 1.589547,1.76919 0.04179,0.3378 -0.174383,0.53162 -0.399556,0.5358 -0.576698,-0.29608 -1.316291,-1.10536 -1.495882,-1.91568 z"
         style="fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.5291667;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
      <path
         inkscape:connector-curvature="0"
         id="path4873"
         d="m 25.503546,248.56346 a 0.25722547,0.29893768 0 0 1 -0.257225,0.29894 0.25722547,0.29893768 0 0 1 -0.257225,-0.29894 0.25722547,0.29893768 0 0 1 0.257225,-0.29894 0.25722547,0.29893768 0 0 1 0.257225,0.29894 z"
         style="opacity:0.97699998;fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.5291667;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1" />
      <path
         sodipodi:nodetypes="ccccc"
         inkscape:connector-curvature="0"
         id="path4871-8"
         d="m 31.002174,245.94777 c 0.02283,-0.22023 0.100249,-0.37803 0.305893,-0.38932 0.480531,1.13314 0.42587,1.62194 1.088135,2.15263 0.0418,0.33779 -0.292366,0.32514 -0.517535,0.32933 -0.478389,-0.54187 -0.785387,-1.34133 -0.876493,-2.09264 z"
         style="fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.5291667;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
      <path
         inkscape:connector-curvature="0"
         id="path4873-6"
         d="m 33.04829,248.47083 a 0.25722547,0.2989377 0 0 1 -0.257223,0.29893 0.25722547,0.2989377 0 0 1 -0.257229,-0.29893 0.25722547,0.2989377 0 0 1 0.257229,-0.29893 0.25722547,0.2989377 0 0 1 0.257223,0.29893 z"
         style="opacity:0.97699998;fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.5291667;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1" />
      <path
         sodipodi:nodetypes="ccccc"
         inkscape:connector-curvature="0"
         id="path4913"
         d="m 18.034598,253.45943 -0.421487,2.96933 4.590283,0.0312 -2.341646,-2.32004 z"
         style="fill:#eaeaea;fill-opacity:1;stroke:none;stroke-width:0.5291667;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
      <path
         sodipodi:nodetypes="ccccc"
         inkscape:connector-curvature="0"
         id="path4896"
         d="m 17.392624,255.96837 c 1.129692,0.27228 3.154943,0.61137 4.81077,0.49158 0.332405,0.21823 0.106528,0.59715 -0.105069,0.74285 -1.597544,0.24851 -3.972075,0.0139 -4.930147,-0.55157 -0.220229,-0.2899 -0.146666,-0.50385 0.224442,-0.68286 z"
         style="fill:#fc7d7d;fill-opacity:1;stroke:#f90f0f;stroke-width:0.5291667;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
      <path
         sodipodi:nodetypes="sssssssss"
         inkscape:connector-curvature="0"
         id="rect4797-6"
         d="m 26.328928,246.31194 0.989132,0.0513 c 0.100217,0.005 0.213967,0.0863 0.181154,0.18114 l -0.179334,0.51838 c -0.03281,0.0948 -0.0808,0.18115 -0.181153,0.18115 h -0.963513 c -0.100363,0 -0.207302,-0.0843 -0.181155,-0.18115 l 0.153715,-0.56961 c 0.02615,-0.0969 0.08093,-0.18634 0.181154,-0.18114 z"
         style="opacity:0.906;fill:#e5f9fc;fill-opacity:0.58333333;stroke:none;stroke-width:0.5291667;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1" />
      <path
         sodipodi:nodetypes="sssssssss"
         inkscape:connector-curvature="0"
         id="rect4797-6-0"
         d="m 33.681155,245.86748 0.989133,0.0513 c 0.100217,0.005 0.213968,0.0864 0.181155,0.18115 l -0.179338,0.51836 c -0.03284,0.0948 -0.08079,0.18116 -0.181149,0.18116 h -0.963511 c -0.100364,0 -0.207307,-0.0843 -0.181157,-0.18116 L 33.5,246.04868 c 0.02614,-0.0969 0.08093,-0.18634 0.181155,-0.18115 z"
         style="opacity:0.906;fill:#e5f9fc;fill-opacity:0.58333333;stroke:none;stroke-width:0.5291667;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1" />
      <ellipse
         style="opacity:1;fill:#9d9d9d;fill-opacity:1;stroke:none;stroke-width:0.09895281;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
         id="path897"
         cx="-86.394173"
         cy="209.6073"
         rx="0.29164925"
         ry="0.26513571"
         transform="rotate(-25.747847)" />
      <ellipse
         style="opacity:1;fill:#9d9d9d;fill-opacity:1;stroke:none;stroke-width:0.09895281;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
         id="path897-6"
         cx="-84.716873"
         cy="208.36876"
         rx="0.29164925"
         ry="0.26513571"
         transform="rotate(-25.747847)" />
      <ellipse
         style="opacity:1;fill:#9d9d9d;fill-opacity:1;stroke:none;stroke-width:0.09895281;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
         id="path897-7"
         cx="-84.449341"
         cy="210.46098"
         rx="0.29164925"
         ry="0.26513571"
         transform="rotate(-25.747847)" />
      <ellipse
         style="opacity:1;fill:#9d9d9d;fill-opacity:1;stroke:none;stroke-width:0.09895281;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
         id="path897-5"
         cx="-88.156723"
         cy="209.99989"
         rx="0.29164925"
         ry="0.26513571"
         transform="rotate(-25.747847)" />
      <ellipse
         style="opacity:1;fill:#9d9d9d;fill-opacity:1;stroke:none;stroke-width:0.09895281;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
         id="path897-3"
         cx="-86.720673"
         cy="211.70154"
         rx="0.29164925"
         ry="0.26513571"
         transform="rotate(-25.747847)" />
    </g>
    <path
       style="fill:#eaeaea;fill-opacity:1;stroke:#818181;stroke-width:0.26458332;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
       d="m 8.553681,274.23353 c 0.0119,0.17091 -0.07403,1.08829 1.020574,1.36966 0.111427,0.3466 0.231498,0.438 0.307183,0.82122 -0.0032,0.11462 -0.131302,0.53224 -0.06153,0.81711 -0.504057,1.01095 1.01145,1.5915 1.262337,0.20779 0.02523,-0.13913 0.174852,-0.45341 0.24847,-0.69368 0.106323,-0.34702 -0.344296,-1.33965 -0.429571,-1.44659 0.188209,0.27321 0.53687,0.87153 0.429571,1.44659 -0.08924,0.72181 0.202055,0.9918 0.292174,1.25272 0.104838,0.35361 0.588337,0.43072 0.833738,0.16123 0.122979,-0.13506 0.20968,-0.54185 0.195475,-0.79533 0.09211,-0.37794 0.08948,-0.71297 0.03484,-1.00218 -0.166157,-0.87941 -0.809007,-1.34108 -0.740115,-1.28623 0.418307,0.33296 0.608901,0.7434 0.77166,1.27097 0.146955,0.80847 0.04664,1.68064 0.428486,1.71771 0.295233,0.0728 0.68146,-0.12993 0.72158,-0.42083 0.0518,-0.37559 -0.06555,-1.20898 -0.06555,-1.20898 -0.05442,-0.72705 -0.436982,-1.48378 -0.595481,-1.72743 0,0 -0.847793,-0.79772 -1.574475,-1.27565 -1.599116,-0.71774 -2.687708,-0.5603 -3.079363,0.7919 z"
       id="path4747-2-5"
       inkscape:connector-curvature="0"
       sodipodi:nodetypes="ccccsscccsccsccscccc" />
    <path
       style="fill:#fc7d7d;fill-opacity:1;stroke:#f90f0f;stroke-width:0.5291667;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
       d="m 8.616028,273.74402 c 1.222472,-0.45697 2.15874,-0.44374 3.017014,-0.30239 0.318429,-0.29377 0.357604,-0.76661 -0.244488,-0.9364 -0.65504,-0.13144 -1.541196,-0.30067 -2.841353,0.26545 -0.575568,0.36573 -0.323844,0.85137 0.06883,0.97334 z"
       id="path4995"
       inkscape:connector-curvature="0"
       sodipodi:nodetypes="ccccc" />
    <path
       style="fill:#eaeaea;fill-opacity:1;stroke:#818181;stroke-width:0.26458332;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
       d="m 31.511947,273.71224 c 0.04379,0.0458 2.087419,0.56395 2.416817,0.93854 0.07681,0.16719 0.446561,0.6629 0.578683,0.98629 0.08168,0.19992 0.164152,0.71795 0.156871,0.99064 -0.0091,0.34155 -0.07059,0.76853 -0.09707,0.9095 -0.02419,0.12881 -0.57254,0.40016 -0.849002,-0.0725 -0.04525,-0.64713 -0.337249,-2.03138 -0.632339,-2.43317 0.09773,0 0.262297,0.63845 0.362277,0.97787 0.05939,0.20158 0.168546,0.5903 0.224807,0.80817 0.08432,0.32655 -0.01758,0.6303 -0.04082,0.85955 -0.09999,0.256 -0.891756,0.30328 -0.999324,0.0951 -0.04132,-0.0799 -0.0481,-0.52163 -0.03555,-0.75549 -0.03932,-0.12116 -0.06438,-0.51809 -0.104917,-0.65316 -0.205459,-0.68425 -0.353101,-1.03113 -0.5283,-1.17248 0.210866,0.3472 0.378409,0.75105 0.467542,0.96636 0.05003,0.12076 0.02691,0.21794 0.117752,0.52443 0.04891,0.1807 0.01742,0.43407 0.03538,0.56871 0.02354,0.17637 -0.163374,0.4343 -0.174434,0.43497 -0.5105,0.25918 -0.657218,0.22122 -0.805524,0.0657 -0.08426,-0.0559 -0.162523,-0.37374 -0.180474,-0.67769 -0.170855,-0.43454 -0.642483,-1.21327 -0.730821,-1.27717 -0.548047,-0.2667 -0.211601,-0.48978 -0.577281,-0.49714 -0.578704,0.002 -1.038342,-0.0157 -1.292522,-0.007 -0.04873,-0.31153 0.394272,-0.36103 0.412369,-0.69298 0.590271,-0.68823 1.37132,-0.93131 2.27587,-0.88621 z"
       id="path4795-7"
       inkscape:connector-curvature="0"
       sodipodi:nodetypes="ccssscccscscscscscccccccc" />
    <path
       style="fill:#fc7d7d;fill-opacity:1;stroke:#f90f0f;stroke-width:0.5291667;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
       d="m 29.287413,274.79963 c 1.112484,-0.787 1.616883,-0.96279 2.486341,-1.0605 0.199926,-0.4757 -0.112924,-0.81741 -0.641557,-0.83319 -0.668086,0.0515 -1.257409,0.42136 -2.425266,1.33723 -0.123646,0.44307 0.252408,0.66579 0.580482,0.55646 z"
       id="path4995-3"
       inkscape:connector-curvature="0"
       sodipodi:nodetypes="ccccc" />
    <path
       style="fill:none;stroke:#0da1a1;stroke-width:0.26458332px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
       d="m 41.660638,258.51318 1.002261,-1.40316 0.968851,1.63702 1.069077,-1.43656 1.202714,1.67043 1.503391,-1.5368 c 0,0 0.80181,1.80407 1.069079,1.80407 0.267269,0 1.403163,-1.67044 1.403163,-1.67044 0,0 0.768402,1.63703 1.035671,1.63703 0.267269,0 0.902033,-1.1359 0.902033,-1.1359 0,0 0.701585,0.90204 0.7684,1.16931 0.06682,0.26727 0.601356,-1.00226 0.601356,-1.00226"
       id="path5168"
       inkscape:connector-curvature="0"
       sodipodi:nodetypes="ccccccscsccc" />
    <path
       style="fill:none;stroke:#0da1a1;stroke-width:0.26458332px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
       d="m 47.20648,261.85406 0.968853,-1.1359 0.668173,1.06908 1.135896,-0.80181 0.668172,1.03567 0.935444,-0.96885 0.801809,0.93544 0.467723,-0.53454"
       id="path5170"
       inkscape:connector-curvature="0"
       sodipodi:nodetypes="cccccccc" />
    <path
       style="fill:none;stroke:#0da1a1;stroke-width:0.26458332px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
       d="m 45.369001,265.02788 0.968854,-1.20271 0.801807,1.10249 0.968852,-1.03568 0.935444,1.1359 0.734992,-1.00226 0.601355,1.23612 1.00226,-1.30293 0.400906,1.1693"
       id="path5172"
       inkscape:connector-curvature="0"
       sodipodi:nodetypes="ccccccccc" />
    <path
       style="opacity:0.906;fill:none;fill-opacity:1;stroke:#0da1a1;stroke-width:0.26458332;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1"
       inkscape:transform-center-y="-0.39264154"
       d="m 43.354873,264.0091 -2.419619,0.0334 1.961505,-2.28902 z"
       id="path5174"
       inkscape:connector-curvature="0"
       sodipodi:nodetypes="cccc" />
  </g>
</svg>
</template>

<script>

export default {
  name: 'Alien',
}
</script>

<style scoped>
  #alien {
    --legVelocity: 1s;
    --blinkDuration: 2s;
    width: 120px;
    height: auto;
  }
  
  #alien #path4915 { 
    animation: svgA_0 var(--legVelocity) 0s infinite alternate;
  }

  #alien #path4826 { 
    animation: svgA_1 var(--legVelocity) 0s infinite alternate;
  }

  #alien #path4915-3 { 
    animation: svgA_2 var(--legVelocity) 0s infinite alternate;
  }

  #alien #path4826-8 { 
    animation: svgA_3 var(--legVelocity) 0s infinite alternate;
  }

  #alien #path4824 { 
    animation: svgA_4 var(--legVelocity) 0s infinite alternate;
  }

  #alien #path4824-5 { 
    animation: svgA_5 var(--legVelocity) 0s infinite alternate;
  }

  #alien #g895 { 
    animation: svgA_6 var(--legVelocity) 0s infinite alternate;
  }

  @keyframes svgA_0 {
    0% {
      d:path('m 18.771103,290.90942 -0.168058,0.64117 c 0.458834,0.98719 0.671468,1.76314 1.130303,2.75034 1.128899,0.98377 2.81794,0.15462 2.960588,-0.44202 -0.633284,-1.34412 -3.019369,-2.40619 -3.922833,-2.94949 z');
    }
    100% {
      d:path('m 15.660033,290.03736 -0.331808,0.5738 c 0.181108,1.07343 0.180727,1.87799 0.361834,2.95144 0.828169,1.24754 2.676456,0.89514 2.971971,0.35756 -0.254838,-1.46382 -2.274606,-3.1197 -3.001997,-3.8828 z');
    }
  }

  @keyframes svgA_1 {
    0% {
      d:path('m 24.550566,263.32947 c -0.100667,0.70093 -0.273627,1.4016 -0.535791,1.96099 l -0.760529,2.42797 c -0.126995,2.99516 0.333597,3.4781 0.69128,4.51999 l 4.916905,4.63019 c 0.956761,0.76112 1.533148,1.96526 1.304759,2.86354 l -1.201835,4.75009 c -0.300324,1.30143 -3.734404,4.61752 -3.734404,3.37171 -0.199722,-2.58281 -0.430933,-4.29517 -0.156488,-7.35215 l -6.312171,-5.16892');
    }
    100% {
      d:path('m 24.550566,263.32947 c -0.100667,0.70093 -0.273627,1.4016 -0.535791,1.96099 l -0.760529,2.42797 c -0.126995,2.99516 0.333597,3.4781 0.69128,4.51999 l 4.916905,4.63019 c 0.944335,0.76166 1.406055,0.94094 1.906115,2.72991 l 0.775512,3.59317 c -0.300324,1.30143 -3.525533,3.07325 -3.525533,1.82744 -1.151002,-2.76641 -1.921977,-4.07636 -2.276679,-5.03618 l -6.979554,-4.65007');
    }
  }

  @keyframes svgA_2 {
    0% {
      d:path('m 24.756332,290.81747 -0.05209,0.75185 c 0.43575,0.92467 0.720368,1.46249 1.156119,2.38716 1.423701,0.86287 2.596164,0.33337 2.728957,-0.22503 0.376717,-1.05686 -2.920282,-2.35264 -3.832986,-2.91398 z');
    }
    100% {
      d:path('m 30.119353,290.66304 0.243372,0.71327 c 0.760061,0.68352 1.230874,1.06899 1.990937,1.75251 1.646846,0.24365 2.522458,-0.69886 2.428456,-1.26509 -0.06232,-1.12026 -3.603839,-1.03696 -4.662765,-1.20069 z');
    }
  }

  @keyframes svgA_3 {
    0% {
      d:path('m 29.195501,283.51166 -0.230146,0.97058 -1.787134,5.48169 c 0.359299,0.38132 0.812992,1.09301 1.320338,1.99251 0.49155,0.80394 0.349546,1.48938 -0.04214,1.99891 -0.581782,0.38276 -1.388684,0.26283 -2.089428,-0.38931 l -1.464149,-2.28408 c 0,0 -0.572879,-0.79843 0.32811,-1.51896 -0.110608,-1.43041 -0.165603,-3.98256 -0.268871,-5.47054 -0.02229,-0.32109 -0.0404,-0.44939 -0.05265,-0.77932 1.498123,0.79981 3.556089,0.73121 4.286062,-0.001 z');
    }
    100% {
      d:path('m 31.380095,282.20776 0.163963,0.98393 0.477053,5.74588 c 0.479003,0.21226 1.173063,0.6925 1.98937,1.32506 0.76471,0.5506 0.899456,1.23751 0.73586,1.85901 -0.387968,0.57833 -1.178285,0.7805 -2.077008,0.45091 l -2.234941,-1.53811 c 0,0 -0.837548,-0.514 -0.286231,-1.5274 -0.656339,-1.27574 -1.696159,-3.60711 -2.368043,-4.93877 -0.144992,-0.28737 -0.211413,-0.39862 -0.350573,-0.69801 1.691012,0.15669 3.561539,-0.70419 3.950686,-1.66205 z');
    }
  }

  @keyframes svgA_4 {
    0% {
      d:path('m 12.743692,261.67141 c 0.305158,2.58023 1.837732,6.15312 0.803556,9.98526 -0.501082,1.14777 -0.441536,3.07304 -0.111236,4.01296 l 7.00015,5.19113 c -1.083266,2.28194 -0.939077,6.53538 -1.094823,8.86885 -0.42266,0.23066 -0.629294,0.54374 -0.570236,1.17981 l 1.35677,2.85119 c 0.669776,0.74788 1.721121,0.72934 2.359602,0.3146 0.387207,-0.41294 0.38418,-1.08708 -0.03933,-1.76969 -0.129385,-0.20856 -0.716159,-1.91847 -1.199465,-2.28095 l 4.493166,-10.04162 c 0.126715,-0.64809 0.247896,-1.29741 -0.547159,-2.15034 l -4.91912,-4.54063');
    }
    100% {
      d:path('m 12.743692,261.67141 c 0.305158,2.58023 1.837732,6.15312 0.803556,9.98526 -0.501082,1.14777 -0.441536,3.07304 -0.111236,4.01296 l 7.00015,5.19113 c -3.044968,3.06733 -2.520744,4.66616 -3.9139,8.18985 -0.42266,0.23066 -0.47244,0.75228 -0.413382,1.38835 l 0.10466,2.70705 c 0.669776,0.74788 1.810009,1.18889 2.44849,0.77415 0.387207,-0.41294 -0.467592,-1.66702 -0.891102,-2.34963 -0.129385,-0.20856 -0.421567,-1.10818 0.512538,-1.73052 l 7.45838,-9.85706 c 0.126715,-0.64809 0.247896,-1.29741 -0.547159,-2.15034 l -4.91912,-4.54063');
    }
  }

  @keyframes svgA_5 {
    0% {
      d:path('m 19.66961,283.93109 c -0.360672,0.0515 -0.364761,4.32958 -0.328281,5.79852 -0.422663,0.23066 -0.629297,0.54374 -0.570236,1.17981 l 1.356766,2.85119 c 0.66978,0.74788 1.721125,0.72934 2.359605,0.3146 0.387207,-0.41294 0.384183,-1.08708 -0.03932,-1.76969 -0.129385,-0.20856 -0.716159,-1.91847 -1.199464,-2.28095 l 2.349599,-5.25104 c -2.584991,0.0905 -3.162864,-0.3905 -3.928672,-0.84244 z');
    }
    100% {
      d:path('m 18.373959,283.5459 c -0.361435,-0.0459 -1.497981,4.07853 -1.851697,5.50471 -0.468648,0.11053 -0.750795,0.35773 -0.862238,0.98674 l 0.553516,3.10866 c 0.447883,0.89851 1.466623,1.15897 2.192122,0.92806 0.482714,-0.29569 0.658273,-0.94658 0.430599,-1.71695 -0.06956,-0.23537 -0.182699,-2.03962 -0.552795,-2.51711 l 3.396091,-4.37076 c -2.516715,-0.59709 -2.686767,-1.2848 -3.305601,-1.92335 z');
    }

  }


 #alien #rect4797 { 
    animation: svgA_6 2s 0s infinite alternate;
  }

  #alien #path5168 { 
    animation: svgA_8 2s 0s infinite alternate;
  }

  #alien #path5170 { 
    animation: svgA_9 2s 0s infinite alternate;
  }

  #alien #path5172 { 
    animation: svgA_10 2s 0s infinite alternate;
  }

  #alien #path5174 { 
    animation: svgA_11 2s 0s infinite alternate;
  }


  @keyframes svgA_6 {
    0.0000% {
      fill: rgb(229, 249, 252);
      stroke: rgb(110, 220, 239);
    }
    25.0000% {
      fill: rgb(229, 249, 252);
      stroke: rgb(110, 220, 239);
    }
    50.0000% {
      fill: rgb(252, 233, 229);
      stroke: rgb(239, 120, 110);
    }
    75.0000% {
      fill: rgb(229, 249, 252);
      stroke: rgb(110, 220, 239);
    }
    100.0000% {
      fill: rgb(252, 229, 250);
      stroke: rgb(240, 123, 232);
    }
  }


  @keyframes svgA_8 {
    0.0000% {
      d:path('m 41.627229,257.31047 0.935444,1.57021 0.935442,-1.60362 0.868625,1.67045 0.634766,-1.67044 1.002261,1.9377 c 0,0 0.80181,-2.03793 1.069079,-2.03793 0.267269,0 1.035667,2.07133 1.035667,2.07133 0,0 0.868628,-1.77065 1.135897,-1.77065 0.267269,0 0.902033,1.97111 0.902033,1.97111 0,0 1.10249,-0.83522 1.703844,-1.63702 0.06682,0.26727 0.434312,1.5368 0.434312,1.5368');
      stroke: rgb(13, 161, 161);
    }
    25.0000% {
      d:path('m 41.660638,258.51318 1.002261,-1.40316 0.968851,1.63702 1.069077,-1.43656 1.202714,1.67043 1.503391,-1.5368 c 0,0 0.80181,1.80407 1.069079,1.80407 0.267269,0 1.403163,-1.67044 1.403163,-1.67044 0,0 0.768402,1.63703 1.035671,1.63703 0.267269,0 0.902033,-1.1359 0.902033,-1.1359 0,0 0.701585,0.90204 0.7684,1.16931 0.06682,0.26727 0.601356,-1.00226 0.601356,-1.00226');
      stroke: rgb(13, 161, 161);
    }
    50.0000% {
      d:path('m 41.627229,258.72788 1.029938,-1.40635 0.793701,1.46743 1.199354,-1.4478 0.493025,1.6841 1.002261,-1.70034 c 0,0 0.80181,1.60009 1.069079,1.60009 0.267269,0 0.893926,-1.56669 0.893926,-1.56669 0,0 0.868628,1.96186 1.135897,1.96186 0.267269,0 1.941467,-1.57242 1.941467,-1.57242 l 0.900645,1.81202 c 0.917267,-0.53593 1.568241,-1.53426 1.568241,-1.53426');
      stroke: rgb(161, 37, 13);
    }
    75.0000% {
      d:path('m 41.660638,258.51318 1.002261,-1.40316 0.968851,1.63702 1.069077,-1.43656 1.202714,1.67043 1.503391,-1.5368 c 0,0 0.80181,1.80407 1.069079,1.80407 0.267269,0 1.403163,-1.67044 1.403163,-1.67044 0,0 0.768402,1.63703 1.035671,1.63703 0.267269,0 0.902033,-1.1359 0.902033,-1.1359 0,0 0.701585,0.90204 0.7684,1.16931 0.06682,0.26727 0.601356,-1.00226 0.601356,-1.00226');
      stroke: rgb(13, 161, 161);
    }
    100.0000% {
      d:path('m 46.47149,264.22607 c -0.864047,0.01 -1.807272,-0.39426 -2.405426,-1.26953 -0.212325,-0.50633 -0.524106,-0.96294 -0.400905,-1.63703 0.111538,-0.62638 0.35585,-1.4257 1.23612,-2.10474 0.334181,-0.24296 1.123686,-0.75037 2.472245,-0.60136 0.969857,0.18629 1.73999,0.38701 2.53906,1.23613 1.268826,1.80326 0.384546,3.1465 0.06682,3.4745 -0.814398,0.84074 -2.706106,0.10022 -2.706106,0.10022 -1.619876,-0.41538 -2.357808,-1.65149 -1.804068,-2.53906 0.60818,-0.97483 0.975787,-0.73718 1.804068,-0.80181 0.396857,0 1.250541,0.26728 1.737251,1.33636 0.290734,0.76526 -1.145771,1.00605 -1.536799,0.26726');
      stroke: rgb(236, 21, 198);
    }
  }

  @keyframes svgA_9 {
    0.0000% {
      d:path('m 45.034915,260.71816 0.835218,1.26953 1.403165,-1.10248 0.801809,1.1693 1.069076,-1.00226 1.202713,1.1359 1.102487,-1.03567 0.668176,1.10248');
      stroke: rgb(13, 161, 161);
    }
    25.0000% {
      d:path('m 47.20648,261.85406 0.968853,-1.1359 0.668173,1.06908 1.135896,-0.80181 0.668172,1.03567 0.935444,-0.96885 0.801809,0.93544 0.467723,-0.53454');
      stroke: rgb(13, 161, 161);
    }
    50.0000% {
      d:path('m 40.971671,261.75759 1.449429,-1.2818 1.592153,1.68509 2.171973,-1.33479 1.258064,1.36009 1.769677,-1.32095 1.196981,1.42118 1.896599,-1.11813');
      stroke: rgb(161, 37, 13);
    }
    75.0000% {
      d:path('m 47.20648,261.85406 0.968853,-1.1359 0.668173,1.06908 1.135896,-0.80181 0.668172,1.03567 0.935444,-0.96885 0.801809,0.93544 0.467723,-0.53454');
      stroke: rgb(13, 161, 161);
    }
    100.0000% {
      d:path('m 39.756341,265.06095 1.937705,-7.21629 v 0 0 0 0 0 0');
      stroke: rgb(236, 21, 198);
    }
  }

  @keyframes svgA_10 {
    0.0000% {
      d:path('m 44.968097,263.75835 0.534541,1.23612 1.23612,-1.30293 1.102486,1.36974 0.80181,-1.1693 0.668174,1.53681 0.801807,-1.60362 0.801808,1.5034 0.400906,-1.36976');
      stroke: rgb(13, 161, 161);
    }
    25.0000% {
      d:path('m 45.369001,265.02788 0.968854,-1.20271 0.801807,1.10249 0.968852,-1.03568 0.935444,1.1359 0.734992,-1.00226 0.601355,1.23612 1.00226,-1.30293 0.400906,1.1693');
      stroke: rgb(13, 161, 161);
    }
    50.0000% {
      d:path('m 40.818324,265.07817 1.471516,-1.6901 1.408727,1.72334 1.820773,-1.35307 1.06908,1.48669 1.570208,-1.46997 0.935442,1.52009 1.035669,-1.33634 1.06908,1.77066');
      stroke: rgb(161, 37, 13);
    }
    75.0000% {
      d:path('m 45.369001,265.02788 0.968854,-1.20271 0.801807,1.10249 0.968852,-1.03568 0.935444,1.1359 0.734992,-1.00226 0.601355,1.23612 1.00226,-1.30293 0.400906,1.1693');
      stroke: rgb(13, 161, 161);
    }
    100.0000% {
      d:path('m 54.222306,258.68023 v 0 0 0 0 0 0 0 l -2.03654,6.55482');
      stroke: rgb(236, 21, 198);
    }
  }

  @keyframes svgA_11 {
    0.0000% {
      opacity: 0.906;
      stroke: rgb(186, 47, 15);
      stroke-opacity: 1;
    }
    25.0000% {
      opacity: 0.906;
      stroke: rgb(13, 161, 161);
      stroke-opacity: 1;
    }
    50.0000% {
      opacity: 0.906;
      stroke: rgb(186, 47, 15);
      stroke-opacity: 0.0132743;
    }
    75.0000% {
      opacity: 0.906;
      stroke: rgb(13, 161, 161);
      stroke-opacity: 1;
    }
    100.0000% {
      opacity: 0;
      stroke: rgb(13, 161, 161);
      stroke-opacity: 1;
    }
  }
  
  
  
  #alien #path4749 { 
    animation: svgA_12 var(--blinkDuration) 0s infinite alternate;
  }

  #alien #path4749-6 { 
    animation: svgA_13 var(--blinkDuration) 0s infinite alternate;
  }

  #alien #path4838 { 
    animation: svgA_14 var(--blinkDuration) 0s infinite alternate;
  }

  #alien #path4840 { 
    animation: svgA_15 var(--blinkDuration) 0s infinite alternate;
  }

  #alien #path4842 { 
    animation: svgA_16 var(--blinkDuration) 0s infinite alternate;
  }

  #alien #path4846 { 
    animation: svgA_17 var(--blinkDuration) 0s infinite alternate;
  }

  #alien #path4848 { 
    animation: svgA_18 var(--blinkDuration) 0s infinite alternate;
  }

  #alien #path4850 { 
    animation: svgA_19 var(--blinkDuration) 0s infinite alternate;
  }

  #alien #path4848-6 { 
    animation: svgA_20 var(--blinkDuration) 0s infinite alternate;
  }

  #alien #path4850-5 { 
    animation: svgA_21 var(--blinkDuration) 0s infinite alternate;
  }

  #alien #path4871 { 
    animation: svgA_22 var(--blinkDuration) 0s infinite alternate;
  }

  #alien #path4873 { 
    animation: svgA_23 var(--blinkDuration) 0s infinite alternate;
  }

  #alien #path4871-8 { 
    animation: svgA_24 var(--blinkDuration) 0s infinite alternate;
  }

  #alien #path4873-6 { 
    animation: svgA_25 var(--blinkDuration) 0s infinite alternate;
  }

  #alien #rect4797-6 { 
    animation: svgA_26 var(--blinkDuration) 0s infinite alternate;
  }

  #alien #rect4797-6-0 { 
    animation: svgA_27 var(--blinkDuration) 0s infinite alternate;
  }


  @keyframes svgA_12 {
    90% {
      d:path('m 29.213148,244.51042 c 0,3.24408 -1.586953,5.98529 -3.763453,5.44134 -2.112552,-0.52797 -3.684798,-2.19726 -3.684798,-5.44134 0,-3.24409 2.650513,-5.40201 4.707291,-5.40201 2.056776,0 2.74096,2.15792 2.74096,5.40201 z');
      fill: rgb(0, 0, 0);
    }
    93.33% {
      d:path('m 29.213148,244.51042 c 0,3.24408 -1.533102,4.59699 -3.709602,4.05304 -2.112552,-0.52797 -3.738649,-0.80896 -3.738649,-4.05304 0,-3.24409 2.637604,-3.36549 4.694382,-3.36549 2.056776,0 2.753869,0.1214 2.753869,3.36549 z');
      fill: rgb(0, 0, 0);
    }
    96.66% {
      d:path('m 29.161375,245.93396 c 0.02008,1.94196 -1.900501,2.10184 -3.612511,2.22422 -2.227104,-0.0946 -3.884934,-0.89427 -3.743609,-3.0112 0.06236,-0.93416 2.067413,-1.92268 4.311048,-1.9446 2.481515,-0.0242 3.028158,1.09538 3.045072,2.73158 z');
      fill: rgb(0, 0, 0);
    }
    100.0000% {
      d:path('m 29.161375,245.93396 c 0,0 -1.981225,0.0323 -3.693235,0.15473 -2.227104,-0.0946 -4.076577,0.29212 -3.6637,-0.20786 0.455055,-0.55106 2.202755,-0.53467 4.44639,-0.55659 2.481515,-0.0242 2.934646,0.0256 2.910545,0.60972 z');
      fill: rgb(107, 107, 107);
    }
  }

  @keyframes svgA_13 {
    90% {
      d:path('m 36.790085,244.29076 c 0,2.98257 -1.640925,5.16793 -3.501212,5.28418 -1.860285,0.19375 -2.950639,-2.30161 -2.950639,-5.28418 0,-2.9826 0.500454,-5.24545 2.282084,-5.24545 1.781629,0 4.169767,2.26285 4.169767,5.24545 z');
      fill: rgb(0, 0, 0);
      stroke-width: 0.529167px;
    }
    93.33% {
      d:path('m 36.784525,244.20306 c -0.0048,2.90629 -1.887904,3.96294 -3.748188,4.07923 -1.860408,0.19181 -2.707836,-1.16248 -2.703,-4.06877 0.0048,-2.90633 0.567795,-3.06323 2.349242,-3.06612 1.781446,-0.003 4.106782,0.14933 4.101946,3.05566 z');
      fill: rgb(0, 0, 0);
      stroke-width: 0.522329px;
    }
    96.66% {
      d:path('m 36.547289,245.74125 c -0.115371,1.26856 -1.767571,1.40978 -3.332824,1.69148 -1.860408,0.19181 -2.811789,-0.6343 -2.964338,-1.89164 -0.184355,-1.51949 1.043515,-2.42388 2.824962,-2.42677 1.781446,-0.003 3.705434,0.0624 3.4722,2.62693 z');
      fill: rgb(0, 0, 0);
      stroke-width: 0.522329px;
    }
    100.0000% {
      d:path('m 36.547289,245.74125 c -0.06565,0.48714 -1.95335,0.15075 -3.541602,0.25638 -1.87685,-0.0675 -2.762398,0.26786 -2.75556,-0.45654 0.0051,-0.5447 1.126405,-0.36976 2.907852,-0.37265 1.781446,-0.003 3.459144,0.0546 3.38931,0.57281 z');
      fill: rgb(107, 107, 107);
      stroke-width: 0.522329px;
    }
  }

  @keyframes svgA_14 {
    90% {
      d:path('m 22.347319,239.78303 c 1.505715,-1.84612 3.408407,-2.55127 5.061084,-2.44712');
    }
    93.33% {
      d:path('m 22.414283,240.83925 c 1.505715,-1.84612 3.654972,-2.02247 5.307649,-1.91832');
    }
    96.66% {
      d:path('m 21.8704,242.34133 c 1.505715,-1.84612 3.654972,-2.02247 5.307649,-1.91832');
    }
    100.0000% {
      d:path('m 21.970846,243.92566 c 1.505715,-1.84612 3.654972,-2.02247 5.307649,-1.91832');
    }
  }

  @keyframes svgA_15 {
    90% {
      d:path('m 31.357159,237.00222 c 2.329523,0.0406 3.384304,1.34421 4.39369,2.55834');
    }
    93.33% {
      d:path('m 31.351084,238.7732 c 2.213179,0.36786 3.422701,0.72689 4.466729,1.84358');
    }
    96.66% {
      d:path('m 31.601657,240.48529 c 2.486688,-0.12875 3.540741,0.34356 4.478347,1.65348');
    }
    100.0000% {
      d:path('m 31.668621,241.54151 c 2.486688,-0.12875 3.540741,0.34356 4.478347,1.65348');
    }
  }

  @keyframes svgA_16 {
    90% {
      d:path('m 24.015809,250.68383 c 0.721671,0.53517 1.831569,0.84915 3.114515,0.30588');
    }
    93.33% {
      d:path('m 23.948845,249.62761 c 0.694938,0.38306 1.763552,0.46214 3.114515,0.30588');
    }
    96.66% {
      d:path('m 23.870303,249.1355 c 0.694938,0.38306 1.763552,0.46214 3.114515,0.30588');
    }
    100.0000% {
      d:path('m 23.769857,247.55117 c 0.694938,0.38306 1.763552,0.46214 3.114515,0.30588');
    }
  }

  @keyframes svgA_17 {
    90% {
      d:path('m 32.636338,250.62822 c 0.922395,0.004 1.811482,-0.0549 2.36369,-0.6396');
    }
    93.33% {
      d:path('m 32.488181,249.41141 c 0.841355,-0.0226 1.585388,-0.12912 2.36369,-0.6396');
    }
    96.66% {
      d:path('m 32.62033,248.88227 c 0.841355,-0.0226 1.585388,-0.12912 2.36369,-0.6396');
    }
    100.0000% {
      d:path('m 32.553366,247.82605 c 0.841355,-0.0226 1.585388,-0.12912 2.36369,-0.6396');
    }
  }

  @keyframes svgA_18 {
    90% {
      d:path('m 26.529327,240.28686 c 0.036,-0.26223 0.1581,-0.46785 0.481752,-0.44244 0.857528,0.47777 1.20565,1.32013 1.346941,2.63489 0.01848,0.33092 -0.266435,0.44213 -0.619397,0.28512 -0.233416,-1.26417 -0.499826,-1.69315 -1.209296,-2.47757 z');
      fill-opacity: 1;
      stroke-width: 0.529167px;
    }
    93.33% {
      d:path('m 26.617198,241.67284 c 0.04399,-0.13616 0.172178,-0.24581 0.494417,-0.24268 0.841166,0.2197 1.162708,0.64282 1.263297,1.3156 0.0083,0.16986 -0.27951,0.2359 -0.626956,0.16589 -0.19409,-0.64389 -0.44679,-0.85663 -1.130758,-1.23881 z');
      fill-opacity: 1;
      stroke-width: 0.379035px;
    }
    96.66% {
      d:path('m 25.342158,244.27317 c -0.03278,-0.13928 -0.175873,-0.40535 0.101533,-0.56935 0.833541,-0.24706 1.316545,-0.0388 1.92578,0.2609 0.09496,0.14108 0.0671,0.45314 -0.266473,0.57294 -0.701626,-0.28398 -0.977794,-0.29118 -1.76084,-0.26449 z');
      fill-opacity: 1;
      stroke-width: 0.379035px;
    }
    100.0000% {
      d:path('m 24.568088,246.04745 c -0.03278,-0.13928 -0.175873,-0.40535 0.101533,-0.56935 0.833541,-0.24706 1.316545,-0.0388 1.92578,0.2609 0.09496,0.14108 0.0671,0.45314 -0.266473,0.57294 -0.701626,-0.28398 -0.977794,-0.29118 -1.76084,-0.26449 z');
      fill-opacity: 0;
      stroke-width: 0.379035px;
    }
  }

  @keyframes svgA_19 {
    90% {
      d:path('m 28.298263,243.70399 a 0.27808158,0.36150607 0 0 1 -0.27808,0.3615 0.27808158,0.36150607 0 0 1 -0.278081,-0.3615 0.27808158,0.36150607 0 0 1 0.278081,-0.36151 0.27808158,0.36150607 0 0 1 0.27808,0.36151 z');
      fill-opacity: 1;
    }
    93.33% {
      d:path('m 28.298263,243.70399 a 0.27808158,0.36150607 0 0 1 -0.27808,0.3615 0.27808158,0.36150607 0 0 1 -0.278081,-0.3615 0.27808158,0.36150607 0 0 1 0.278081,-0.36151 0.27808158,0.36150607 0 0 1 0.27808,0.36151 z');
      fill-opacity: 1;
    }
    96.66% {
      d:path('m 28.154496,244.42323 a 0.27808158,0.36150607 0 0 1 -0.27808,0.3615 0.27808158,0.36150607 0 0 1 -0.278081,-0.3615 0.27808158,0.36150607 0 0 1 0.278081,-0.36151 0.27808158,0.36150607 0 0 1 0.27808,0.36151 z');
      fill-opacity: 1;
    }
    100.0000% {
      d:path('m 27.380426,246.19751 a 0.27808158,0.36150607 0 0 1 -0.27808,0.3615 0.27808158,0.36150607 0 0 1 -0.278081,-0.3615 0.27808158,0.36150607 0 0 1 0.278081,-0.36151 0.27808158,0.36150607 0 0 1 0.27808,0.36151 z');
      fill-opacity: 0;
    }
  }

  @keyframes svgA_20 {
    90% {
      d:path('m 33.612522,240.5075 c 0.03603,-0.26223 0.158096,-0.46785 0.481751,-0.44243 0.79033,0.47777 1.502099,1.2367 1.791869,2.55146 0.01848,0.33092 -0.322047,0.35869 -0.675013,0.2017 -0.348662,-1.29774 -1.043609,-1.72656 -1.598607,-2.31073 z');
      fill-opacity: 1;
      stroke-width: 0.529167px;
    }
    93.33% {
      d:path('m 33.692259,241.76517 c 0.045,-0.12086 0.173881,-0.21888 0.495951,-0.21844 0.772131,0.1907 1.456178,0.51239 1.699985,1.10217 0.0071,0.15032 -0.333711,0.17473 -0.680493,0.11528 -0.303157,-0.57999 -0.981799,-0.75165 -1.515443,-0.99901 z');
      fill-opacity: 1;
      stroke-width: 0.356551px;
    }
    96.66% {
      d:path('m 32.700678,243.94538 c 0,0 -0.08687,-0.50363 0.235198,-0.50319 0.757334,-0.0427 1.586302,-0.2072 1.830109,0.38255 0.0071,0.15032 -0.06369,0.34173 -0.410468,0.28228 -0.405993,-0.22197 -1.10623,-0.0743 -1.654839,-0.16164 z');
      fill-opacity: 1;
      stroke-width: 0.356551px;
    }
    100.0000% {
      d:path('m 31.997401,245.17352 c 0,0 -0.08687,-0.50363 0.235198,-0.50319 0.757334,-0.0427 1.586302,-0.2072 1.830109,0.38255 0.0071,0.15032 -0.06369,0.34173 -0.410468,0.28228 -0.405993,-0.22197 -1.10623,-0.0743 -1.654839,-0.16164 z');
      fill-opacity: 0;
      stroke-width: 0.356551px;
    }
  }

  @keyframes svgA_21 {
    90% {
      d:path('m 36.048853,243.60483 a 0.27808158,0.36150607 0 0 1 -0.278082,0.3615 0.27808158,0.36150607 0 0 1 -0.278081,-0.3615 0.27808158,0.36150607 0 0 1 0.278081,-0.36151 0.27808158,0.36150607 0 0 1 0.278082,0.36151 z');
      fill-opacity: 1;
    }
    93.33% {
      d:path('m 36.048853,243.60483 a 0.27808158,0.36150607 0 0 1 -0.278082,0.3615 0.27808158,0.36150607 0 0 1 -0.278081,-0.3615 0.27808158,0.36150607 0 0 1 0.278081,-0.36151 0.27808158,0.36150607 0 0 1 0.278082,0.36151 z');
      fill-opacity: 1;
    }
    96.66% {
      d:path('m 35.662732,244.11471 a 0.27808158,0.36150607 0 0 1 -0.278082,0.3615 0.27808158,0.36150607 0 0 1 -0.278081,-0.3615 0.27808158,0.36150607 0 0 1 0.278081,-0.36151 0.27808158,0.36150607 0 0 1 0.278082,0.36151 z');
      fill-opacity: 1;
    }
    100.0000% {
      d:path('m 34.959455,245.34285 a 0.27808158,0.36150607 0 0 1 -0.278082,0.3615 0.27808158,0.36150607 0 0 1 -0.278081,-0.3615 0.27808158,0.36150607 0 0 1 0.278081,-0.36151 0.27808158,0.36150607 0 0 1 0.278082,0.36151 z');
      fill-opacity: 0;
    }
  }

  @keyframes svgA_22 {
    90% {
      d:path('m 22.847865,246.67945 c 0.02282,-0.22021 0.100248,-0.37803 0.305891,-0.38931 0.480533,1.13313 0.927285,1.2385 1.589547,1.76919 0.04179,0.3378 -0.174383,0.53162 -0.399556,0.5358 -0.576698,-0.29608 -1.316291,-1.10536 -1.495882,-1.91568 z');
      fill-opacity: 1;
      stroke-width: 0.529167px;
    }
    93.33% {
      d:path('m 22.839736,246.55124 c 0.02763,-0.14439 0.108415,-0.24922 0.31402,-0.26111 0.454964,0.72985 0.898779,0.78888 1.548457,1.12109 0.03431,0.21981 -0.185822,0.3512 -0.410773,0.35888 -0.569389,-0.18079 -1.290169,-0.69333 -1.451704,-1.21886 z');
      fill-opacity: 1;
      stroke-width: 0.427291px;
    }
    96.66% {
      d:path('m 22.838342,246.52926 c 0.02845,-0.13139 0.109816,-0.22714 0.315414,-0.23913 0.450581,0.66071 0.893892,0.7118 1.541413,1.00999 0.03303,0.19958 -0.187783,0.32027 -0.412696,0.32855 -0.568136,-0.16103 -1.285691,-0.6227 -1.444131,-1.09941 z');
      fill-opacity: 1;
      stroke-width: 0.407276px;
    }
    100.0000% {
      d:path('m 23.611313,245.31783 c 0.02845,-0.13139 0.109816,-0.22714 0.315414,-0.23913 0.450581,0.66071 0.893892,0.7118 1.541413,1.00999 0.03303,0.19958 -0.187783,0.32027 -0.412696,0.32855 -0.568136,-0.16103 -1.285691,-0.6227 -1.444131,-1.09941 z');
      fill-opacity: 0;
      stroke-width: 0.407276px;
    }
  }

  @keyframes svgA_23 {
    90% {
      d:path('m 25.503546,248.56346 a 0.25722547,0.29893768 0 0 1 -0.257225,0.29894 0.25722547,0.29893768 0 0 1 -0.257225,-0.29894 0.25722547,0.29893768 0 0 1 0.257225,-0.29894 0.25722547,0.29893768 0 0 1 0.257225,0.29894 z');
      fill-opacity: 1;
    }
    93.33% {
      d:path('m 25.564927,247.66485 a 0.25722547,0.29893768 0 0 1 -0.257225,0.29894 0.25722547,0.29893768 0 0 1 -0.257225,-0.29894 0.25722547,0.29893768 0 0 1 0.257225,-0.29894 0.25722547,0.29893768 0 0 1 0.257225,0.29894 z');
      fill-opacity: 1;
    }
    96.66% {
      d:path('m 25.555957,247.52337 a 0.25722547,0.29893768 0 0 1 -0.257225,0.29894 0.25722547,0.29893768 0 0 1 -0.257225,-0.29894 0.25722547,0.29893768 0 0 1 0.257225,-0.29894 0.25722547,0.29893768 0 0 1 0.257225,0.29894 z');
      fill-opacity: 1;
    }
    100.0000% {
      d:path('m 26.328928,246.31194 a 0.25722547,0.29893768 0 0 1 -0.257225,0.29894 0.25722547,0.29893768 0 0 1 -0.257225,-0.29894 0.25722547,0.29893768 0 0 1 0.257225,-0.29894 0.25722547,0.29893768 0 0 1 0.257225,0.29894 z');
      fill-opacity: 0;
    }
  }

  @keyframes svgA_24 {
    90% {
      d:path('m 31.002174,245.94777 c 0.02283,-0.22023 0.100249,-0.37803 0.305893,-0.38932 0.480531,1.13314 0.42587,1.62194 1.088135,2.15263 0.0418,0.33779 -0.292366,0.32514 -0.517535,0.32933 -0.478389,-0.54187 -0.785387,-1.34133 -0.876493,-2.09264 z');
      fill-opacity: 1;
      stroke-width: 0.529167px;
    }
    93.33% {
      d:path('m 30.996697,245.86138 c 0.02607,-0.16914 0.105752,-0.29124 0.31137,-0.30293 0.463304,0.86142 0.401457,1.23688 1.055244,1.63384 0.03676,0.25829 -0.296906,0.25354 -0.521925,0.26009 -0.469918,-0.40825 -0.764792,-1.01648 -0.844689,-1.591 z');
      fill-opacity: 1;
      stroke-width: 0.462997px;
    }
    96.66% {
      d:path('m 31.064029,246.1429 c -0.01716,-0.12511 0.0268,-0.23828 0.214293,-0.3235 0.639162,0.4132 0.673565,0.69241 1.375682,0.71741 0.09708,0.16227 -0.21296,0.28438 -0.41966,0.3734 -0.534668,-0.10178 -0.956107,-0.40565 -1.170315,-0.76731 z');
      fill-opacity: 1;
      stroke-width: 0.39363px;
    }
    100.0000% {
      d:path('m 31.32331,245.26635 c -0.01716,-0.12511 0.0268,-0.23828 0.214293,-0.3235 0.639162,0.4132 0.673565,0.69241 1.375682,0.71741 0.09708,0.16227 -0.21296,0.28438 -0.41966,0.3734 -0.534668,-0.10178 -0.956107,-0.40565 -1.170315,-0.76731 z');
      fill-opacity: 0.0176991;
      stroke-width: 0.39363px;
    }
  }

  @keyframes svgA_25 {
    90% {
      d:path('m 33.04829,248.47083 a 0.25722547,0.2989377 0 0 1 -0.257223,0.29893 0.25722547,0.2989377 0 0 1 -0.257229,-0.29893 0.25722547,0.2989377 0 0 1 0.257229,-0.29893 0.25722547,0.2989377 0 0 1 0.257223,0.29893 z');
      fill-opacity: 1;
    }
    93.33% {
      d:path('m 33.214465,247.43273 a 0.25722547,0.2989377 0 0 1 -0.257223,0.29893 0.25722547,0.2989377 0 0 1 -0.257229,-0.29893 0.25722547,0.2989377 0 0 1 0.257229,-0.29893 0.25722547,0.2989377 0 0 1 0.257223,0.29893 z');
      fill-opacity: 1;
    }
    96.66% {
      d:path('m 33.421874,246.74408 a 0.25722547,0.2989377 0 0 1 -0.257223,0.29893 0.25722547,0.2989377 0 0 1 -0.257229,-0.29893 0.25722547,0.2989377 0 0 1 0.257229,-0.29893 0.25722547,0.2989377 0 0 1 0.257223,0.29893 z');
      fill-opacity: 1;
    }
    100.0000% {
      d:path('m 33.681155,245.86753 a 0.25722547,0.2989377 0 0 1 -0.257223,0.29893 0.25722547,0.2989377 0 0 1 -0.257229,-0.29893 0.25722547,0.2989377 0 0 1 0.257229,-0.29893 0.25722547,0.2989377 0 0 1 0.257223,0.29893 z');
      fill-opacity: 0.0176991;
    }
  }

  @keyframes svgA_26 {
    90% {
      fill-opacity: 0.583333;
    }
    93.33% {
      fill-opacity: 0.583333;
    }
    96.66% {
      fill-opacity: 0;
    }
    100.0000% {
      fill-opacity: 0;
    }
  }

  @keyframes svgA_27 {
    90% {
      fill-opacity: 0.583333;
    }
    93.33% {
      fill-opacity: 0.583333;
    }
    96.66% {
      fill-opacity: 0;
    }
    100.0000% {
      fill-opacity: 0;
    }
  }

</style>
