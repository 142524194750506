<template>
  <div class="BackgroundRain">
    <div class="background1"></div>
    <div class="background2"></div>
    <background-rain-drops/>
      <div class="background3">
      <svg viewBox="0 0 132.29166 132.29167" id="thunder" class="svgAnimator">
        <defs id="defs2">
          <filter style="color-interpolation-filters:sRGB" id="filter1547" x="-0.026199118" width="1.0523982" y="-0.024274286" height="1.0485486">
            <feGaussianBlur stdDeviation="1.4049374" id="feGaussianBlur1549"></feGaussianBlur>
          </filter>
        </defs>
        <g id="layer1" transform="translate(0,-164.70832)">
          <path style="fill:none;stroke:#fafafa;stroke-width:0.26458332px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1" d="m 127.37797,165.0863 0.56697,3.40178 -4.91369,1.32292 2.83482,3.02381 h -6.80357 l -0.18899,2.45684 -12.09524,0.37798 0.37798,3.40179 -3.96875,0.18898 -0.18899,2.07887 -2.07887,0.18899 -4.346725,0.75595 v 1.51191 l -3.590774,-1.13393 v 2.26786 l -3.590774,-1.51191 0.188989,5.10268 -1.322916,0.37798 0.944941,3.96875 -4.15774,1.70089 1.700893,3.40179 -1.700893,1.13392 -3.779761,-1.13393 0.755952,6.04762 -6.236608,-3.77976 0.944942,6.04762 -6.236608,-1.70089 0.188989,1.5119 -5.858631,-4.91369 -0.566965,2.64583 -6.047618,-4.34672 -1.511906,4.34672 -3.96875,-2.26785 -3.023809,6.42559 -3.401785,-0.75595 1.889881,3.77976 -6.992561,2.83483 -0.755952,5.10267 -5.480653,0.56697 -0.944941,4.15773 4.346726,2.45685 -2.834823,3.02381 10.583334,7.74851 -6.614584,2.07887 1.700893,4.91369 -5.102678,2.83482 4.346726,5.29167 0.377976,3.40178 5.480656,3.40179 -2.834823,2.45685 h 7.559524 l -4.346725,9.63839 22.867558,-4.91369 -3.96875,3.2128 3.96875,0.37797 -0.755951,6.80357 3.401785,0.18899 -2.267858,6.04762 1.322917,1.5119 -2.83482,4.72471 4.724702,3.02381 0.188989,1.32291 7.9375,-1.88988 -2.834823,6.80357 h 4.724702 l -0.188987,4.34673 -5.669642,1.5119" id="path881" inkscape:connector-curvature="0" sodipodi:nodetypes="ccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccc"></path>
          <path style="fill:none;stroke:#ffffff;stroke-width:0.26458332px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1" d="m 35.52976,229.15326 -8.504462,6.23661 0.377976,2.45684 -4.346727,0.94494 -0.94494,9.44941 -6.803571,-2.07887 3.968749,6.61458 -3.590773,1.88988 2.267857,4.34673 -5.291667,2.64583 0.944941,5.29167 -10.9613097,2.07887 4.9136903,6.2366 -3.5907736,3.77977 9.827381,6.04762 -4.7247026,1.5119 -4.1577381,4.15774 v 2.26786 l -3.96874983,2.45684 -1.70089283,3.77976" id="path883" inkscape:connector-curvature="0"></path>
          <path style="fill:none;stroke:#ffffff;stroke-width:0.26458332px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1" d="m 34.017857,223.6726 -4.913691,-0.75595 v 2.64584 l -4.157738,-4.34673 -2.834821,4.34673 -4.724703,-1.32292 1.322917,2.26786 -4.724702,1.5119 -0.188988,2.07887" id="path887" inkscape:connector-curvature="0"></path>
          <path style="fill:none;stroke:#ffffff;stroke-width:0.26458332px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1" d="m 76.729166,206.2857 -1.13393,8.88244 7.181548,2.07887 -6.614584,2.07887 2.456847,3.96875 -1.889881,1.5119" id="path895" inkscape:connector-curvature="0" sodipodi:nodetypes="cccccc"></path>
          <g id="g927" transform="translate(-1.969591,27.17283)" style="stroke:#4698ff;stroke-opacity:1;filter:url(#filter1547)">
            <path id="path881-3" d="m 129.34756,137.91347 0.56697,3.40178 -4.91369,1.32292 2.83482,3.02381 h -6.80357 l -0.18899,2.45684 -12.09524,0.37798 0.37798,3.40179 -3.96875,0.18898 -0.18899,2.07887 -2.07887,0.18899 -4.346723,0.75595 v 1.51191 l -3.590774,-1.13393 v 2.26786 l -3.590775,-1.51191 0.18899,5.10268 -1.322917,0.37798 0.944941,3.96875 -4.157739,1.70089 1.700892,3.40179 -1.700892,1.13392 -3.779761,-1.13393 0.755951,6.04762 -6.236607,-3.77976 0.944941,6.04762 -6.236608,-1.70089 0.188989,1.5119 -5.858631,-4.91369 -0.566965,2.64583 -6.047619,-4.34672 -1.511906,4.34672 -3.96875,-2.26785 -3.023809,6.42559 -3.401785,-0.75595 1.889882,3.77976 -6.992562,2.83483 -0.755951,5.10267 -5.480654,0.56697 -0.94494,4.15773 4.346725,2.45685 -2.834822,3.02381 10.583333,7.74851 -6.614583,2.07887 1.700892,4.91369 -5.102677,2.83482 4.346726,5.29167 0.377975,3.40178 5.480656,3.40179 -2.834822,2.45685 h 7.559524 l -4.346726,9.63839 22.867559,-4.91369 -3.96875,3.2128 3.96875,0.37797 -0.755951,6.80357 3.401785,0.18899 -2.267858,6.04762 1.322917,1.5119 -2.83482,4.72471 4.724702,3.02381 0.188989,1.32291 7.9375,-1.88988 -2.834823,6.80357 h 4.724702 l -0.188987,4.34673 -5.669642,1.5119" style="fill:none;stroke:#4698ff;stroke-width:0.26458332px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"></path>
            <path id="path883-6" d="m 37.499351,201.98043 -8.504463,6.23661 0.377976,2.45684 -4.346726,0.94494 -0.94494,9.44941 -6.803571,-2.07887 3.968749,6.61458 -3.590773,1.88988 2.267857,4.34673 -5.291667,2.64583 0.944941,5.29167 -10.9613102,2.07887 4.9136906,6.2366 -3.5907737,3.77977 9.8273813,6.04762 -4.724703,1.5119 -4.157738,4.15774 v 2.26786 l -3.9687499,2.45684 -1.7008929,3.77976" style="fill:none;stroke:#4698ff;stroke-width:0.26458332px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"></path>
            <path id="path887-7" d="m 35.987448,196.49977 -4.913691,-0.75595 v 2.64584 l -4.157738,-4.34673 -2.834821,4.34673 -4.724703,-1.32292 1.322917,2.26786 -4.724702,1.5119 -0.188988,2.07887" style="fill:none;stroke:#4698ff;stroke-width:0.26458332px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"></path>
            <path id="path895-5" d="m 78.698757,179.11287 -1.13393,8.88244 7.181548,2.07887 -6.614583,2.07887 2.456846,3.96875 -1.889881,1.5119" style="fill:none;stroke:#4698ff;stroke-width:0.26458332px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"></path>
          </g>
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
import BackgroundRainDrops from "./BackgroundRainDrops.vue";

export default {
  name: 'BackgroundRain',
    components: {
    BackgroundRainDrops
  },
}
</script>

<style scoped>
  * {
    --thunder-duration: -10s;
    --thunder-delay: 15s;
    --thunder-repetition: infinite;
  }

  .BackgroundRain {
    display: block;
    height: 100%;
    width: 100%;
    background-color: #fff;
  }

  .background1 {
    position: fixed;
    z-index: 1;
    display: block;
    height: 100%;
    width: 100%;
    background: linear-gradient(0deg, #441165, #04b5ea, #d4058b, #441165);
    background-size: 100% 1600%;
    animation: colorShift 20s ease -10s infinite alternate;
  }

  .background2 {
    position: fixed;
    z-index: 1;
    display: block;
    height: 100%;
    width: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0,0,0,0.8));
    background-size: 100% 400%;
    animation: darknessThunder var(--thunder-duration) ease var(--thunder-delay) infinite alternate;
  }

  .background3 {
    position: fixed;
    z-index: 1;
    display: block;
    height: 100%;
    width: 100%;
    text-align: center;
  }

  #thunder {
    width: auto;
    height: 100%;
  }
  
  #thunder path {
    opacity: 0;
  }

  #thunder.svgAnimator #path881,
  #thunder.svgAnimator #path883,
  #thunder.svgAnimator #path887,
  #thunder.svgAnimator #path895 {
    animation: svgA_2 var(--thunder-duration) ease var(--thunder-delay) var(--thunder-repetition) alternate;
  }

  #thunder.svgAnimator #g927 {
    animation: svgA_6 var(--thunder-duration) ease var(--thunder-delay) var(--thunder-repetition) alternate;
  }

  @keyframes colorShift {
    0%{
      background-position: 0 0;
    }

    100%{
      background-position: 0 100%;
    }
  }

  @keyframes darknessThunder {
    0%{
      background-size: 100% 300%;
    }
    99.5%{
      background-size: 100% 900%;
      opacity: 1;
    }
    99.6%{
      background-size: 100% 100%;
      opacity: 0.5;
    }
    99.7%{
      background-size: 100% 900%;
      opacity: 1;
    }
    99.8%{
      background-size: 100% 100%;
    }
    99.9%{
      background-size: 100% 900%;
    }
    100%{
      background-size: 100% 100%;
    }
  }

  @keyframes svgA_2 {

    99.5% {
      opacity: 0;
    }
    99.6% {
      opacity: 1;
    }
    99.65% {
      opacity: 0;
    }
    99.7% {
      opacity: 1;
    }
    99.75% {
      opacity: 0;
    }
    99.8% {
      opacity: 1;
    }
    99.9% {
      opacity: 0;
    }
  }

  @keyframes svgA_6 {
    99.5% {
      opacity: 0;
    }
    99.6% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
    }
</style>
