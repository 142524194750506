<template>
  <router-view/>
</template>
<style>

  html, body, #app {
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
</style>
